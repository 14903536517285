import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import Portfolio from "../components/Portfolio";
import SEO from "../components/Seo";

const PortfolioPage = () => {
  const data = useStaticQuery(graphql`
    query PortfolioQuery {
      allContentfulPortfolio {
        edges {
          node {
            metaTitle
            metaDescription
            portfolioSections {
              title
              slug
            }
            portfolioProjects {
              thumbnailImage {
                fluid(maxWidth: 800, quality: 100) {
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
              title
              slug
            }
          }
        }
      }
      allContentfulPortfolioSection {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulPortfolio.edges[0].node;

  return (
    <Layout>
      <SEO metaDescription={dataNode.metaDescription} title={dataNode.metaTitle} />
      <Portfolio
        projects={dataNode.portfolioProjects}
        sections={dataNode.portfolioSections}
      />
    </Layout>
  );
};

export default PortfolioPage;
